import {
  faArrowRight,
  faHome,
  faRotateLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/buttons/Button';
import LocalImage from '../components/images/LocalImage';
import Translated from '../components/translation/Translated';
import { useDetectLocale } from '../hooks/useDetectLocale';

export type PageNotFoundProps = {};

export function PageNotFoundPage({}: PageNotFoundProps) {
  useDetectLocale();

  return (
    <section
      style={{ background: '#776E64' }}
      className="relative min-h-screen md:-my-14 flex justify-center md:items-center"
    >
      <Helmet>
        <title>404 - Signature Products GmbH</title>
      </Helmet>
      <div className="relative z-10 text-white text-center md:text-xl text-lg max-w-prose px-4 md:py-20 py-4">
        <LocalImage
          src="404"
          className="block max-h-[40vh] mx-auto mb-8"
          loading="eager"
        />
        <h1 className="uppercase font-bold md:text-5xl text-2xl tracking-[0.2em] leading-snug mb-8">
          <Translated ns="404" k="title">
            Oops! This page does not exist.
          </Translated>
        </h1>
        <p className=" mx-auto font-medium leading-relaxed mb-12">
          <Translated ns="404" k="description">
            Maybe you just took a wrong turn or you were interested enough to
            find some eastereggs.
          </Translated>
        </p>
        <div className="flex justify-center flex-wrap gap-4">
          <Button
            type="custom"
            className="inline-block border-2 bg-white border-white text-black text-lg scale-100 hover:scale-105 active:scale-95 transition-transform"
            link="/"
            title="Home"
          >
            <Translated ns="404" k="cta">
              Take me home
            </Translated>
            <FontAwesomeIcon className="sm:ml-12 ml-6" icon={faHome} />
          </Button>
          <Button
            type="custom"
            className="inline-block border-2 border-white text-white text-lg scale-100 hover:scale-105 active:scale-95 transition-transform"
            onClick={() => history.back()}
            title="Home"
          >
            <Translated ns="404" k="back">
              Take me back
            </Translated>
            <FontAwesomeIcon className="sm:ml-12 ml-6" icon={faRotateLeft} />
          </Button>
        </div>
      </div>
    </section>
  );
}

export default PageNotFoundPage;
